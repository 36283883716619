<script lang="tsx" setup>
import { routes } from 'vue-router/auto-routes';
import I18nComp from '../components/i18nComp/index.vue';
import Primevue from '@/components/primevue/primevue.vue';

useHead({
  // Titles
  title: 'Hello World',
  titleTemplate: '%s %separator %siteName',
  // Template params
  templateParams: { separator: '|', siteName: 'My App' },
  // Classes
  bodyAttrs: { class: { overflow: true } },
  // Deduping
  // script: [{ key: '123', src: '/script.js' }],
});
;

// https://cn.vuejs.org/guide/extras/render-function#typing-functional-components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FComponent: import('vue').FunctionalComponent<{ prop: string }> = (props, context) => (
  // <>
  <div border="1 solid pink" text="pink">
    <span>This is a functional component with prop: {JSON.stringify(props)}</span>
  </div>
  // </>
);
</script>

<template>
  <h1>Index Page</h1>
  <ul>
    <li><router-link class="green" :to="{ name: 'DataLoadersId', params: { id: 520 } }">Data Loaders</router-link></li>
    <li><router-link class="green" :to="{ name: 'TsEnumUtil' }">TS Enum Util</router-link></li>
    <li><router-link class="green" :to="{ name: 'SomePage' }">Some Page</router-link></li>
    <li><router-link class="green" :to="{ name: '中文页面' }">中文-页面.page.vue</router-link></li>
    <li><router-link class="green" :to="{ name: 'Api' }">Api</router-link></li>
    <li><router-link class="green" :to="{ name: 'InfiniteLoading' }">Infinite Loading</router-link></li>
  </ul>

  <div b="1px solid pink" mt-2>
    <I18nComp />
  </div>

  <FComponent prop="Hello World" style="margin-top: 8px"></FComponent>
  <div text-orange></div>

  <div b="1px solid pink" mt-2>
    <ReusableTemplate />
  </div>
  <div b="1px solid pink" mt-2>
    <ReactivityTransform />
  </div>
  <div b="1px solid pink" mt-2>
    <DefineRender />
  </div>
  <Icons />

  <div :class="$style.hero" mt-2>
    <h1><i>🔌</i> Vite Plugin Webfont DL <i>⚡</i></h1>
    <h2>Fonts are downloaded directly from Google Fonts</h2>
    <p>{{ JSON.stringify({ $style }) }}</p>
  </div>

  <div b="1px solid pink" mt-2 p-2>
    <SendSms />
  </div>

  <div b="1px solid pink" mt-2 p-2>
    <Primevue />
  </div>

  <div b="1px solid pink" mt-2 p-2>
    <pre>{{ JSON.stringify(routes, null, 2) }}</pre>
  </div>
</template>

<style module>
/* https://cn.vuejs.org/api/sfc-css-features#css-modules */
h1 {
  font-family: 'Press Start 2P', cursive;
  color: #646cff;
}

h2 {
  font-family: 'Fira Code', monospace;
  background-color: #42b983;
}

.hero {
  border: 1px solid #42b983;
}
</style>
