import _definePage_default_0 from '/workspace/examples/vue-ts-example/src/pages/data-loaders.[id].vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/workspace/examples/vue-ts-example/src/pages/index-page.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/:path(.*)',
    name: '$Path',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/[...path].vue'),
    props: true,
    /* no children */
  },
  {
    path: '/a',
    /* internal name: 'A' */
    /* no component */
    children: [
      {
        path: '',
        name: 'A',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/a/index.vue'),
        /* no children */
      },
      {
        path: 'a',
        name: 'AA',
        component: () => import('/workspace/examples/vue-ts-example/src/pages/a/a.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/api',
    name: 'Api',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/api.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/data-loaders/:id',
    name: 'DataLoadersId',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/data-loaders.[id].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/index-page',
    name: 'IndexPage',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/index-page.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/infinite-loading',
    name: 'InfiniteLoading',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/infinite-loading.page.vue'),
    /* no children */
  },
  {
    path: '/md-page',
    name: 'MdPage',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/md-page.md'),
    /* no children */
  },
  {
    path: '/some-page',
    name: 'SomePage',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/some-page.page.vue'),
    /* no children */
  },
  {
    path: '/ts-enum-util',
    name: 'TsEnumUtil',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/ts-enum-util.vue'),
    /* no children */
  },
  {
    path: '/中文-页面',
    name: '中文页面',
    component: () => import('/workspace/examples/vue-ts-example/src/pages/中文-页面.page.vue'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

